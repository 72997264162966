<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>Tanggal</th>
                    <th>Nama Siswa</th>
                    <th>Kelas</th>
                    <th>Asal Sekolah</th>
                    <th>No HP</th>
                    <th>Tujuan Kedinasan</th>
                    <th>Catatan Konsultasi</th>
                    <th>Ket. Hasil Konsulasi</th>
                    <th>UP</th>
                    <th>No. KWT</th>
                    <th>PIC</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <input type="hidden" name="bukti" value="" v-model="form.bukti" id="bukti" />
                                <datepicker v-model="form.tanggal" :options="{format:'dd/mm/yyyy', startView: 'days', minViewMode: 'days'}" :change="onChange()"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Siswa</label>
                                <v-select taggable :options="optAudience" v-model="form.rekap_audience_id" :reduce="(opt) => opt.id" @input="setSiswa($event)" item-text="value" class="siswa"/>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Asal Sekolah</label>
                                <input id="asal_sekolah" class="form-control asal_sekolah" v-model="form.asal_sekolah" type="text" name="asal_sekolah" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Kelas</label>
                                <input id="kelas" class="form-control kelas" v-model="form.kelas" type="text" name="kelas" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">No. HP</label>
                                <input id="no_hp" class="form-control no_hp" v-model="form.no_hp" type="number" name="no_hp" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tujuan Kedinasan</label>
                                <textarea class="form-control" v-model="form.tujuan_kedinasan"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Catatan Konsultasi</label>
                                <textarea class="form-control" v-model="form.catatan"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Keterangan Hasil Konsultasi</label>
                                <textarea class="form-control" v-model="form.note_hasil"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Uang Pendaftaran</label>
                                <input id="followup" class="form-control followup" v-model="form.followup" type="text" required="required" name="followup" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">No.KWT</label>
                                <input id="no_kwt" class="form-control no_kwt" v-model="form.no_kwt" type="text" required="required" name="no_kwt" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">PIC</label>
                                <select v-model="form.staff_id_pic" class="form-control">
                                    <option v-for="staff in optPic" v-bind:value="staff.id">{{ staff.label }}</option>
                                </select>
                            </div>
                            
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import datepicker from '@/components/Datepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
    name: 'RekapHasilKonsultasi',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            optSiswa: [],
            optPic: [''],
            optAudience: [],
            formTitle: 'Tambah',
            form: {
                tanggal: moment().format('DD/MM/YYYY'),
                siswa_id: '',
                catatan: '',
                note_hasil: '',
                followup: '',
                no_kwt: '',
                staff_id_pic: '',
                tujuan_kedinasan: '',
                rekap_audience_id: '',
                nama: '',
                asal_sekolah: '',
                no_hp: '',
                kelas: '',
            },
            form_data: ''
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/pr/rekap_hasil_konsultasi/siswa')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optSiswa = js.data;
            })
        authFetch('/pr/rekap_hasil_konsultasi/pic')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optPic = js.data;
            })
        authFetch('/pr/rekap_hasil_konsultasi/audience')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optAudience = js.data;
        });
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        onChange: function() {
            var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
            namahari = namahari.split(" ");
            if (this.form.tanggal) {
                var split = this.form.tanggal.split('/')
                var tgl = new Date(split[2],split[1]-1,split[0]);
                var hari = tgl.getDay();

                this.form.hari = namahari[hari];
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;

            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/pr/rekap_hasil_konsultasi';
            if (this.method == 'PUT') urlSubmit = '/pr/rekap_hasil_konsultasi/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        },
        setSiswa: function(data){
            // this.form.rekap_audience_id = data.id
            console.log(data)
            if (data.id) {
                this.form.rekap_audience_id = data.id
            } else {
                this.form.rekap_audience_id = 0
                this.form.nama = data.label
            }
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List Hasil Konsultasi",
            "roles": this.$route.params.roles,
            "ajax": "/pr/rekap_hasil_konsultasi",
            scrollX: true,
            "columns": [
                { "data": "start" },
                { "data": "display_name" },
                { "data": "kelas" },
                { "data": "asal_sekolah" },
                { "data": "no_hp" },
                { "data": "tujuan_kedinasan" },
                { "data": "catatan" },
                { "data": "note_hasil" },
                { "data": "followup" },
                { "data": "no_kwt" },
                { "data": "pic" },
            ],
            filterBy: [1, 2, 3, 4, 5, 9, 10],
            "rowCallback": function(row, data) {

            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Hasil Konsultasi';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.form.tanggal = evt.data.start;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Hasil Konsultasi';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/pr/rekap_hasil_konsultasi/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });

    }
}
</script>